import { MenuItem, Text } from '@chakra-ui/react';
import { ParticipantDocument, ParticipantFile } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { usePermission } from '../../hooks/usePermission';
import { ParticipantFilesAndDocumentsDeleteModal } from './ParticipantFilesAndDocumentsDeleteModal';

interface Props {
  participantId: string;
  selectedRows: (ParticipantFile | ParticipantDocument)[];
}

export const ParticipantFilesAndDocumentsDeleteMenuItem = (props: Props) => {
  const { participantId, selectedRows } = props;

  const hasDeleteFilePermission = usePermission('delete', 'participantFile');
  const hasDeleteDocumentPermission = usePermission('delete', 'participantDocument');
  const hasPermission = hasDeleteFilePermission && hasDeleteDocumentPermission;

  return (
    <MenuItem
      icon={<FaRegTrashAlt />}
      onClick={() =>
        showModal(ParticipantFilesAndDocumentsDeleteModal, {
          participantId,
          selectedRows,
        })
      }
      isDisabled={!hasPermission || selectedRows.length === 0}
      data-pw="selectedReportsDeleteMenuItem"
    >
      <Text color="danger">Delete {selectedRows.length} files or documents</Text>
    </MenuItem>
  );
};
