import {
  Text,
  Divider,
  Grid,
  GridItem,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { LabelType } from '@piccolohealth/pbs-common';
import { Empty } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { HookedAvatarUploader } from '../../components/forms/HookedAvatarUploader';
import { HookedFieldControl } from '../../components/forms/HookedFieldControl';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedKeyContactsControl } from '../../components/forms/HookedKeyContactsControl';
import { HookedLabelChooser } from '../../components/forms/HookedLabelChooser';
import { HookedLocationChooser } from '../../components/forms/HookedLocationChooser';
import { HookedSelect } from '../../components/forms/HookedSelect';
import { HookedSingleDatepicker } from '../../components/forms/HookedSingleDatepicker';
import { gridItemSize } from '../../utils/gridItemSize';
import { FormErrorsBadge } from '../../components/generic/FormErrorsBadge';
import { participantStatusOptions } from './ParticipantStatusTag';
import { FormErrorsAlert } from '../../components/generic/FormErrorsAlert';

interface Props {
  isDisabled?: boolean;
  openToTabIndex?: number;
}

export const ParticipantForm = (props: Props) => {
  const { isDisabled, openToTabIndex } = props;

  const [tabIndex, setTabIndex] = React.useState(openToTabIndex ?? 0);

  const fieldGroups = (useWatch({ name: 'fieldGroups' }) as Common.ParticipantFieldGroup[]) ?? [];

  return (
    <Tabs
      index={tabIndex}
      onChange={setTabIndex}
      orientation="vertical"
      variant="soft-rounded"
      size="sm"
      minH="xl"
      isLazy
      lazyBehavior="keepMounted"
    >
      <TabList>
        <Tab>
          <HStack>
            <Text>General</Text>
            <FormErrorsBadge
              names={[
                'firstName',
                'lastName',
                'dob',
                'gender',
                'status',
                'locationId',
                'picture',
                'address',
                'suburb',
                'state',
                'postcode',
              ]}
            />
          </HStack>
        </Tab>
        <Tab>
          <HStack>
            <Text>Key contacts</Text>
            <FormErrorsBadge names={['keyContacts']} />
          </HStack>
        </Tab>
        {fieldGroups.map((fieldGroup) => (
          <Tab key={`${fieldGroup.id}-tab`}>{fieldGroup.template.name}</Tab>
        ))}
      </TabList>

      <TabPanels ml={6} pl={6} borderLeftWidth="1px" borderLeftColor="gray.200">
        <TabPanel>
          <FormStack>
            <FormErrorsAlert />
            <FormSection heading="Basic details" description="Who is the participant?">
              <FormStack orientation="horizontal">
                <HookedFormItem
                  name="firstName"
                  label="First name"
                  isRequired
                  isDisabled={isDisabled}
                >
                  <HookedInput name="firstName" size="sm" placeholder="Enter first name" />
                </HookedFormItem>
                <HookedFormItem
                  name="lastName"
                  label="Last name"
                  isRequired
                  isDisabled={isDisabled}
                >
                  <HookedInput name="lastName" size="sm" placeholder="Enter last name" />
                </HookedFormItem>
              </FormStack>

              <FormStack orientation="horizontal">
                <HookedFormItem name="dob" label="Date of birth" isRequired isDisabled={isDisabled}>
                  <HookedSingleDatepicker
                    name="dob"
                    size="sm"
                    placeholder="Pick a date"
                    isDisabled={isDisabled}
                  />
                </HookedFormItem>
                <HookedFormItem name="gender" label="Gender" isRequired isDisabled={isDisabled}>
                  <HookedSelect
                    name="gender"
                    size="sm"
                    placeholder="Choose a gender"
                    isDisabled={isDisabled}
                    options={[
                      {
                        label: Common.renderGender(Common.Gender.Male),
                        value: Common.Gender.Male,
                        raw: Common.Gender.Male,
                      },
                      {
                        label: Common.renderGender(Common.Gender.Female),
                        value: Common.Gender.Female,
                        raw: Common.Gender.Female,
                      },
                      {
                        label: Common.renderGender(Common.Gender.Transgender),
                        value: Common.Gender.Transgender,
                        raw: Common.Gender.Transgender,
                      },
                      {
                        label: Common.renderGender(Common.Gender.GenderNeutral),
                        value: Common.Gender.GenderNeutral,
                        raw: Common.Gender.GenderNeutral,
                      },
                      {
                        label: Common.renderGender(Common.Gender.NonBinary),
                        value: Common.Gender.NonBinary,
                        raw: Common.Gender.NonBinary,
                      },
                      {
                        label: Common.renderGender(Common.Gender.NotSpecified),
                        value: Common.Gender.NotSpecified,
                        raw: Common.Gender.NotSpecified,
                      },
                    ]}
                  />
                </HookedFormItem>
              </FormStack>

              <FormStack orientation="horizontal">
                <HookedFormItem name="status" label="Status" isRequired>
                  <HookedSelect
                    name="status"
                    size="sm"
                    optionVariant="tag"
                    placeholder="Choose a status"
                    options={Object.values(participantStatusOptions)}
                  />
                </HookedFormItem>

                <HookedFormItem
                  name="locationId"
                  label="Location"
                  isRequired
                  isDisabled={isDisabled}
                >
                  <HookedLocationChooser name="locationId" size="sm" isDisabled={isDisabled} />
                </HookedFormItem>
              </FormStack>

              <FormStack orientation="horizontal">
                <HookedFormItem name="ndisNumber" label="NDIS number" isDisabled={isDisabled}>
                  <HookedInput name="ndisNumber" size="sm" placeholder="Enter NDIS number" />
                </HookedFormItem>
                <HookedFormItem name="email" label="Email" isDisabled={isDisabled}>
                  <HookedInput name="email" size="sm" placeholder="Enter email address" />
                </HookedFormItem>
              </FormStack>

              <FormStack orientation="horizontal">
                <HookedFormItem name="phone" label="Phone number" isDisabled={isDisabled}>
                  <HookedInput name="phone" size="sm" placeholder="Enter phone number" />
                </HookedFormItem>
                <HookedFormItem name="labelIds" label="Labels" isDisabled={isDisabled}>
                  <HookedLabelChooser
                    name="labelIds"
                    type={LabelType.Participant}
                    isDisabled={isDisabled}
                  />
                </HookedFormItem>
              </FormStack>

              <HookedFormItem name="picture" label="Profile picture" isDisabled={isDisabled}>
                <HookedAvatarUploader
                  name="picture"
                  w="96px"
                  h="96px"
                  direction="row"
                  isDisabled={isDisabled}
                />
              </HookedFormItem>
            </FormSection>

            <Divider />

            <FormSection heading="Address details" description="Where is the participant located?">
              <FormStack orientation="horizontal">
                <HookedFormItem name="address" label="Address" isDisabled={isDisabled}>
                  <HookedInput name="address" size="sm" placeholder="Enter address" />
                </HookedFormItem>
                <HookedFormItem name="suburb" label="Suburb" isDisabled={isDisabled}>
                  <HookedInput name="suburb" size="sm" placeholder="Enter suburb" />
                </HookedFormItem>
              </FormStack>

              <FormStack orientation="horizontal">
                <HookedFormItem name="state" label="State" isDisabled={isDisabled}>
                  <HookedSelect
                    name="state"
                    size="sm"
                    placeholder="Choose a state"
                    isDisabled={isDisabled}
                    options={Common.STATES.map((state) => ({
                      label: state.long,
                      value: state.short,
                      raw: state,
                    }))}
                  />
                </HookedFormItem>
                <HookedFormItem name="postcode" label="Postcode" isDisabled={isDisabled}>
                  <HookedInput name="postcode" size="sm" placeholder="Enter postcode" />
                </HookedFormItem>
              </FormStack>
            </FormSection>
          </FormStack>
        </TabPanel>
        <TabPanel>
          <FormStack>
            <FormErrorsAlert />
            <FormSection heading="Key contacts" description="Key contacts for a participant">
              <HookedKeyContactsControl name="keyContacts" isDisabled={isDisabled} />
            </FormSection>
          </FormStack>
        </TabPanel>
        {fieldGroups.map((fieldGroup, fieldGroupIndex) => {
          const content = P.run(() => {
            if (P.isEmpty(fieldGroup.fields)) {
              return <Empty title="No fields found" />;
            }

            return (
              <FormStack>
                <FormErrorsAlert />
                <FormSection heading={fieldGroup.template.name}>
                  <Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
                    {fieldGroup.fields.map((field, fieldIndex) => (
                      <GridItem key={field.id} colSpan={gridItemSize(field.template.type)}>
                        <HookedFormItem
                          key={field.id}
                          name={`fieldGroups.${fieldGroupIndex}.fields.${fieldIndex}.value`}
                          label={field.template.name}
                          isDisabled={isDisabled}
                        >
                          <HookedFieldControl
                            name={`fieldGroups.${fieldGroupIndex}.fields.${fieldIndex}.value`}
                            type={field.template.type}
                            attributes={field.template.attributes}
                            isDisabled={isDisabled}
                          />
                        </HookedFormItem>
                      </GridItem>
                    ))}
                  </Grid>
                </FormSection>
              </FormStack>
            );
          });

          return <TabPanel key={`${fieldGroup.id}-panel`}>{content}</TabPanel>;
        })}
      </TabPanels>
    </Tabs>
  );
};
