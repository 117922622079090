import gql from 'graphql-tag';

export const DeleteParticipantDocuments = gql`
  mutation DeleteParticipantDocuments(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentIds: [ID!]!
  ) {
    deleteParticipantDocuments(
      organizationId: $organizationId
      participantId: $participantId
      participantDocumentIds: $participantDocumentIds
    )
  }
`;
