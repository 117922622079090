import { Stack, Text } from '@chakra-ui/react';
import { ParticipantDocument, ParticipantFile } from '@piccolohealth/pbs-common';
import React from 'react';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';
import { createModal } from '../../components/generic/Modal';
import { useDeleteParticipantDocumentsMutation } from '../../graphql/hooks/useDeleteParticipantDocumentsMutation';
import { useDeleteParticipantFilesMutation } from '../../graphql/hooks/useDeleteParticipantFilesMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { isParticipantFile } from '../../utils/fileOrDocument';

interface Props {
  participantId: string;
  selectedRows: (ParticipantFile | ParticipantDocument)[];
}

export const ParticipantFilesAndDocumentsDeleteModal = createModal<Props>((props) => {
  const { participantId, selectedRows, ...rest } = props;
  const { hide } = rest.modal;
  const { organization, successToast, errorToast } = useAppContext();
  const deleteParticipantFilesMutation = useDeleteParticipantFilesMutation();
  const deleteParticipantDocumentsMutation = useDeleteParticipantDocumentsMutation();

  const isLoading =
    deleteParticipantFilesMutation.isLoading || deleteParticipantDocumentsMutation.isLoading;

  const onDelete = React.useCallback(async () => {
    const participantFileIds = selectedRows.filter(isParticipantFile).map((f) => f.id);
    const participantDocumentIds = selectedRows
      .filter((p) => !isParticipantFile(p))
      .map((f) => f.id);

    await Promise.all([
      deleteParticipantFilesMutation.mutateAsync({
        organizationId: organization.id,
        participantId,
        participantFileIds,
      }),
      deleteParticipantDocumentsMutation.mutateAsync({
        organizationId: organization.id,
        participantId,
        participantDocumentIds,
      }),
    ])
      .then(async () => {
        hide();
        successToast('Files and documents deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting files or documents: ${err.message}`);
      });
  }, [
    selectedRows,
    deleteParticipantFilesMutation,
    deleteParticipantDocumentsMutation,
    organization.id,
    participantId,
    hide,
    successToast,
    errorToast,
  ]);

  return (
    <DeleteConfirmModal
      modal={rest.modal}
      heading={`Do you want to delete ${selectedRows.length} files or documents?`}
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting these files or documents <strong>will remove associated data.</strong>
          </Text>
        </Stack>
      }
      requireConfirm
      isLoading={isLoading}
      onDelete={async () => onDelete()}
    />
  );
});
