import gql from 'graphql-tag';

export const Participants = gql`
  query Participants($organizationId: ID!, $request: GetPaginatedParticipantsRequest) {
    organization(organizationId: $organizationId) {
      id
      participants(request: $request) {
        results {
          id
          organizationId
          firstName
          lastName
          gender
          dob
          address
          suburb
          postcode
          state
          status
          ndisNumber
          email
          phone
          picture
          assignedUsers {
            id
            user {
              id
            }
            labels {
              id
              name
              description
              color
            }
          }
          location {
            id
            name
            color
          }
          labels {
            id
            name
            description
            color
          }
          createdAt
          updatedAt
        }
        pagination {
          total
          remaining
          offset
          limit
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
