import { Button, HStack, Spacer, Stack } from '@chakra-ui/react';
import { FileUploadDropZone } from '@piccolohealth/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Route, Routes } from 'react-router-dom';
import { showModal } from '../../components/generic/Modal';
import { useParticipantFilesAndDocumentsFilterQuery } from '../../graphql/hooks/useParticipantQuery';
import { useParticipantFilesAndDocumentsFilter } from '../../hooks/useParticipantFilesAndDocumentsFilter';
import { ParticipantDocumentCreateModal } from '../participantDocument/ParticipantDocumentCreateModal';
import { ParticipantDocumentEditor } from '../participantDocument/ParticipantDocumentEditor';
import { ParticipantFilesAndDocumentsFilterControls } from './ParticipantFilesAndDocumentsFilterControls';
import { ParticipantFilesAndDocumentsTable } from './ParticipantFilesAndDocumentsTable';
import { ParticipantFileUploadModal } from './ParticipantFileUploadModal';
import { ParticipantFilesAndDocumentsActionMenu } from './ParticipantFilesandDocumentsActionMenu';

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantDocumentsContent = (props: Props) => {
  const { participantId, isDisabled } = props;

  const filter = useParticipantFilesAndDocumentsFilter();

  const { isLoading, error, filesAndDocuments, rowSelection } =
    useParticipantFilesAndDocumentsFilterQuery(participantId, filter);

  const onFilesDrag = React.useCallback(
    (files: File[]) => {
      showModal(ParticipantFileUploadModal, { participantId, files });
    },
    [participantId],
  );

  const onClickUpload = React.useCallback(() => {
    showModal(ParticipantFileUploadModal, { participantId });
  }, [participantId]);

  const onClickCreateNewDocument = () => {
    showModal(ParticipantDocumentCreateModal, { participantId });
  };

  return (
    <Stack spacing={4} px={6} py={4}>
      <FileUploadDropZone onFilesChange={onFilesDrag} />
      <HStack w="100%">
        <ParticipantFilesAndDocumentsFilterControls filter={filter} />
        <Spacer />
        <Button
          colorScheme="purple"
          size="sm"
          leftIcon={<FaPlus />}
          onClick={onClickCreateNewDocument}
          isDisabled={isDisabled}
        >
          Create new document
        </Button>
        <Button
          colorScheme="purple"
          size="sm"
          leftIcon={<FaPlus />}
          isDisabled={isDisabled}
          onClick={onClickUpload}
        >
          Upload file
        </Button>
        <ParticipantFilesAndDocumentsActionMenu
          participantId={participantId}
          selectedRows={rowSelection.selectedRows}
        />
      </HStack>
      <ParticipantFilesAndDocumentsTable
        isLoading={isLoading}
        isDisabled={props.isDisabled}
        refetch={() => Promise.resolve()}
        error={error}
        filesAndDocuments={filesAndDocuments}
        rowSelection={rowSelection}
      />
    </Stack>
  );
};

export const ParticipantFilesAndDocuments = (props: Props) => {
  const { participantId } = props;

  return (
    <>
      <Routes>
        <Route
          path="documents/:documentId"
          element={
            <ParticipantDocumentEditor
              participantId={participantId}
              isDisabled={props.isDisabled}
            />
          }
        />
        <Route
          path="documents"
          index
          element={
            <ParticipantDocumentsContent
              participantId={participantId}
              isDisabled={props.isDisabled}
            />
          }
        />
      </Routes>
    </>
  );
};
