import gql from 'graphql-tag';

export const DeleteParticipantFiles = gql`
  mutation DeleteParticipantFiles(
    $organizationId: ID!
    $participantId: ID!
    $participantFileIds: [ID!]!
  ) {
    deleteParticipantFiles(
      organizationId: $organizationId
      participantId: $participantId
      participantFileIds: $participantFileIds
    )
  }
`;
