import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { DeleteParticipantFiles } from '../mutations/deleteParticipantFiles.mutation';
import { DeleteParticipantFilesMutation, DeleteParticipantFilesMutationVariables } from '../types';

export const useDeleteParticipantFilesMutation = (
  options?: MutationOptions<
    DeleteParticipantFilesMutation,
    DeleteParticipantFilesMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteParticipantFiles), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
