import React from 'react';
import { DurationInput, DurationInputProps } from './DurationInput';
import { useController } from 'react-hook-form';
import { Duration } from '@piccolohealth/util';

interface Props extends Omit<DurationInputProps, 'value' | 'onChange'> {
  name: string;
}

export const HookedDurationInput = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const onChange = (duration: Duration) => {
    inputProps.onChange(duration.as('seconds'));
  };

  const value = Duration.fromObject({ seconds: inputProps.value ?? 1 });

  return <DurationInput {...rest} value={value} onChange={onChange} />;
};
